import { useEffect, useState } from "react";
// import OutsideClickHandler from "react-outside-click-handler";
import Popup from "./Popup";
import {
  getAllUsers,
  fetchUserByStatus,
  searchUser,
  getAllBatchBUsers,
  DownloadUser,
  getAllWeb3Users,
  getTalentCityResidents,
  getTalentCityCommercial,
} from "../../lib/data";

import search from "../../images/svg/search.svg";
import accept from "../../images/svg/accept.svg";
import reject from "../../images/svg/reject.svg";
import arrow from "../../images/svg/arrow-down.svg";
import loader from "../../images/gif/loader-purple.gif";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Talentcityapplicants({ displayPage, setTabOption, tabOption }) {

  const [batchUsers, setBatchBUsers] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [date, setDate] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formAction, setFormAction] = useState("Send to");
  const [commercialUsers, setCommercialUsers] = useState([]);
  const [residentUsers, setResidentUsers] = useState([]);
  const [showEditor, setShowEditor] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const [activeButton, setActiveButton] = useState(false);
  const [downloadBtn, setDownloadBtn] = useState(false);
  const [selectAll, setSelectAll] = useState(null);
  const [duration, setDuration] = useState("");
  const [selectId, setSelectId] = useState([]);
  // const [tabOption, setTabOption] = useState('interest');
  // const [checkAll, setCheckAll] = useState(false)

  const navigate = useNavigate();


  useEffect(() => {
    const d = new Date();
    setYear(d.getFullYear());
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let monthh = months[d.getMonth()];
    setMonth(monthh);
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let dayss = days[d.getDay()];
    setDay(dayss);
    const dates = d.getDate();
    if (dates === 1 || dates === 21 || dates === 31) {
      setDate(`${dates}st`);
    } else if (dates === 2 || dates === 22) {
      setDate(`${dates}nd`);
    } else {
      setDate(`${dates}th`);
    }
  }, [page]);

  useEffect(() => {
    setLoading(true)
    getResidentUsersAll()
    getCommercialUsersAll()
  }, []);

  const getResidentUsersAll = () => {
    setLoading(true)
    getTalentCityResidents()
      .then((response) => response.data)
      .then((resp) => {
        setLoading(false)
        // console.log(resp);
        if (resp.status === 'success') {
          setLoading(true);
          setResidentUsers(resp.data.data);
          setLoading(false);
          // setShowPagination(true);
        }
      })
      .catch((err) => {
        // console.log(err);
        // window.location.reload();
        setLoading(false)
      });
  };
  const getCommercialUsersAll = () => {
    setLoading(true)
    // getAllWeb3Users(page)
    getTalentCityCommercial()
      .then((response) => response.data)
      .then((resp) => {
        // console.log(resp);
        setLoading(false)
        if (resp.status === 'success') {
          setLoading(true);
          setCommercialUsers(resp.data.data);
          setLoading(false);
          // setShowPagination(true);
        }
      })
      .catch((err) => {
        setLoading(false)
        // console.log(err);
        // window.location.reload();
      });
  };


  const doSearch = (e) => {
    // setLoading(true);
    // if (e.target.value === "") {
    // //   getUsersall();
    // //   getBatchB();
    // } else {
    //   searchUser(e.target.value)
    //     .then((response) => response.data)
    //     .then((resp) => {
    //       if (resp?.data) {
    //         setUsers(resp.data);
    //         setLoading(false);
    //         setShowPagination(false);
    //       } else {
    //         toast.error("Search is currently unavailable");
    //       }
    //     })
    //     .catch((err) => {
    //       toast.error("Something went wrong. Try again!!");
    //     });
    // }
  };
  
  const url = `https://grazac-academy-admin.onrender.com/api/v1/admin/downloadWeb3?ids=${selectId}`;

  function formatDate(isoDate) {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

function formatCurrency(amount) {
  return `NGN${amount.toLocaleString('en-NG')}`;
}



  return (
    <div className="dashboard-content">
      <Popup
        show={showModal}
        formAction={formAction}
        handleClose={() => {
          setShowModal(false);
          setShowEditor(false);
        }}
        emails={selectedUsers}
      />
      <div>
        <h3>Hello Admin</h3>
        <div className="dashboard-content-top">
          <p>
            {day} {month} {date}, {year}
          </p>
          <div className="dashboard-content-search">
            <input
              type="text"
              placeholder="Search"
              onChange={doSearch}
            />
            <img src={search} alt="search" />
          </div>
        </div>
        <div className="disp-flex">
          <p className={tabOption === 'interest'?'talent-tab-active':"text2"} onClick={()=>setTabOption('interest')}>
            Interest Form Data
          </p>
          <p className={tabOption === 'residency'?'talent-tab-active':"text2"} onClick={()=>setTabOption('residency')}>
            Residency Applicant Data
          </p>
          {/* <div className="dashboard-content-actions">
            <div className="dashboard-content-actions-drop">
              
              <div
                className=" dashboard-content-actions-downloadBtn"
                style={{
                  backgroundColor: downloadBtn ? "#773DD3" : "#fff",
                }}
              >
                <a href={url}>Export Details</a>
              </div>

            </div>
          </div> */}
        </div>
        {tabOption === 'interest' &&<div className="dashboard-content-table">
          <table>
            <thead>
              <tr style={{background: '#F5F8FF'}}>
                {/* <th>
                  <label className="container">
                    <input type="checkbox" id="checkAll" />
                    <span className="checkmark"></span>
                  </label>
                </th> */}
                <th style={{color: '#000'}}>Business Name</th>
                <th style={{color: '#000'}}>Email Address</th>
                <th style={{color: '#000'}}>Phone Number</th>
                <th style={{color: '#000'}}>Investment Amount</th>
                <th style={{color: '#000'}}>Date Applied</th>
                {/* <th style={{color: '#000'}}>Inspection Fee</th> */}
                {/* <th style={{color: '#000'}}>Date</th> */}
              </tr>
            </thead>
            <tbody>
              {!loading &&
                commercialUsers?.map((item, idx) => {
                // testData?.map((item, idx) => {
                  // console.log(item);
                  
                  return (
                  <tr key={idx}>
                    {/* <td>
                      <label className="container">
                        <input
                          type="checkbox"
                          // id={`check-box${item._id}`}
                          id={item._id}
                          className="checkbox"
                        />
                        <span
                          className="checkmark"
                          onClick={() => {
                            userSelected(`${item.email}`, `${item._id}`);
                          }}
                        ></span>
                      </label>
                    </td> */}

                    <td
                      onClick={() => {
                        navigate(`/talent-city/commercialdetails?user=${item._id}`);
                      }}
                    >
                      {item.businessName}
                    </td>
                    <td
                      onClick={() => {
                        navigate(`/talent-city/commercialdetails?user=${item._id}`);
                      }}
                    >
                      {item.email}
                    </td>
                    <td
                      onClick={() => {
                        navigate(`/talent-city/commercialdetails?user=${item._id}`);
                      }}
                    >
                      {item.phoneNumber}
                    </td>
                    <td
                      onClick={() => {
                        navigate(`/talent-city/commercialdetails?user=${item._id}`);
                      }}
                    >
                      {item.investmentAmount}
                    </td>
                    <td
                      onClick={() => {
                        navigate(`/talent-city/commercialdetails?user=${item._id}`);
                      }}
                    >
                      {/* <p className="duration"> */}
                        {formatDate(item.dateApplied)}
                        {/* {item.date} */}
                      {/* </p> */}
                    </td>
                    {/* <td
                      onClick={() => {
                        navigate(`/talent-city/commercialdetails?user=${item._id}`);
                      }}
                      
                    >
                      <span className="fee paid">{item.inspectionFee?'paid':''}</span>
                    </td> */}
                  </tr>
                )})}
            </tbody>
          </table>
          {loading && (
            <img
              src={loader}
              alt="loader"
              style={{ width: "100px", margin: "50px auto", display: "block" }}
            />
          )}
          {/* {showPagination && (
            <div className="disp-flex dashboard-content-table-pagination">
              <p>Rows per page: {users.length}</p>
            </div>
          )} */}
        </div>}

        {tabOption === 'residency' && <div className="dashboard-content-table">
          <table>
            <thead>
              <tr style={{background: '#F5F8FF'}}>
                {/* <th>
                  <label className="container">
                    <input type="checkbox" id="checkAll" />
                    <span className="checkmark"></span>
                  </label>
                </th> */}
                <th style={{color: '#000'}}>Name</th>
                <th style={{color: '#000'}}>Email Address</th>
                <th style={{color: '#000'}}>Phone Number</th>
                <th style={{color: '#000'}}>Date Applied</th>
                <th style={{color: '#000'}}>Inspection Fee</th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                residentUsers?.map((item, idx) => {
                // testData?.map((item, idx) => {
                  // console.log(item);
                  
                  return (
                  <tr key={idx}>
                    {/* <td>
                      <label className="container">
                        <input
                          type="checkbox"
                          // id={`check-box${item._id}`}
                          id={item._id}
                          className="checkbox"
                        />
                        <span
                          className="checkmark"
                          onClick={() => {
                            userSelected(`${item.email}`, `${item._id}`);
                          }}
                        ></span>
                      </label>
                    </td> */}

                    <td
                      onClick={() => {
                        navigate(`/talent-city/residentialdetails?user=${item._id}`);
                      }}
                    >
                      {item.fullName}
                    </td>
                    <td
                      onClick={() => {
                        navigate(`/talent-city/residentialdetails?user=${item._id}`);
                      }}
                    >
                      {item.email}
                    </td>
                    <td
                      onClick={() => {
                        navigate(`/talent-city/residentialdetails?user=${item._id}`);
                      }}
                    >
                      {item.phoneNumber}
                    </td>
                    <td
                      onClick={() => {
                        navigate(`/talent-city/residentialdetails?user=${item._id}`);
                      }}
                    >
                      {/* <p className="duration"> */}
                        {formatDate(item.dateApplied)}
                        {/* {item.date} */}
                      {/* </p> */}
                    </td>
                    <td
                      onClick={() => {
                        navigate(`/talent-city/residentialdetails?user=${item._id}`);
                      }}
                      
                    >
                      <span className="fee paid">{item.inspectionFee?'paid':''}</span>
                    </td>
                  </tr>
                )})}
            </tbody>
          </table>
          {loading && (
            <img
              src={loader}
              alt="loader"
              style={{ width: "100px", margin: "50px auto", display: "block" }}
            />
          )}
          {/* {showPagination && (
            <div className="disp-flex dashboard-content-table-pagination">
              <p>Rows per page: {users.length}</p>
            </div>
          )} */}
        </div>}
      </div>
    </div>
  );
}

export default Talentcityapplicants;
